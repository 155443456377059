import React from "react";
import { Link } from "gatsby";
import "./card.css";
import "../images/profile.jpg";

const Card = props => {
  const { link, imgSrc, title, description } = props;

  return (
    <Link className="project-thumb" to={link}>
      {imgSrc && (
        <img
          className="project-thumb-image"
          src={require(`../../content/blog/${imgSrc}`)}
          alt={`${title} cover image`}
        />
      )}
      <h2 className="project-thumb-title">{title}</h2>
      <span className="project-thumb-desc">{description}</span>
    </Link>
  );
};

export default Card;
