import React from "react";

import projects from "../data/projects.json";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";

import "./playground.css";

const Works = () => (
  <Layout>
    <SEO title="Playground" />
    <div className="project-container">
      {projects &&
        projects.map(project => (
          <Card
            link={project.url}
            imgSrc={project.imgSrc}
            title={project.title}
            description={project.description}
          />
        ))}
    </div>
  </Layout>
);

export default Works;
